import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../components/Layout'
import { BackButton } from '../components/UI/graphics/back-button'

const BlogPost = ({ data }) => {
  const { date, title } = data.markdownRemark.frontmatter

  return (
    <Layout>
      <div
        className="grid-content--center-6"
        style={{ marginTop: '5rem', minHeight: '100svh' }}
      >
        <div>
          <h2 className="text-3xl leading-12 text-gray-800 lg:text-4xl lg:leading-14 mb-2">
            {title}
          </h2>
        </div>

        <div className="mt-4">
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </div>
        <BackButton button_text="Return to FAQ" link="/" />
      </div>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date
        title
      }
    }
  }
`
